<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Touchfree',
    metaInfo: {
      title: 'LEE HENG GLOBAL CO., LTD. TOUCHFREE',
      titleTemplate: 'LEE HENG GLOBAL CO., LTD.',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keyword', content: 'LEE HENG GLOBAL CO., LTD., 이행글로벌 주식회사, 고무장갑, 니트릴, 라텍스, 니트릴장갑, 나이트릴장갑, 니트릴 글러브, 라텍스 장갑, 라텍스장갑, 글러브, 장갑, 고무장갑 솔루션, 무역, 고무장갑 무역, 고무장갑 도매, 고무장갑 소매, 도매, 소매, 도소매, 실험용 장갑, 진료용 장갑, 의료용 장갑, 수술용 장갑, 가정용 장갑, 클린룸 장갑, 산업용 장갑, 안전 장갑, 화학요법용 장갑, 수술장갑, 진료장갑, 실험장갑, 터치프리, 고무장갑 원스톱 솔루션' },
        { name: 'description', content: '이행글로벌 주식회사는 20여년 간의 글로벌 고무장갑 업력을 바탕으로 고객의 니즈에 따른 고무장갑 생산과 공급 그리고 유통 솔루션을 제공합니다.' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'touchfree-main',
        'slide',
        'touchfree-spec',
        'certification',
        'banner',
        'info-company',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'Touchfree',
      },
    },
  }
</script>
